import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn, faUsers, faChartLine, faHandshake, faTools, faGamepad, faDollarSign, faThumbsUp, faArrowRight, faDatabase, faRobot, faMoneyBill, faCalculator } from '@fortawesome/free-solid-svg-icons';
import { faRedditAlien, faTwitter, faFacebook, faTwitch, faYoutube, faTiktok } from '@fortawesome/free-brands-svg-icons';
import { Helmet } from 'react-helmet';
import Header from '../../component/layout/header';
import { Link } from 'react-router-dom';
import Footer from '../../component/layout/footer';
import Navigate from '../../../../util/Navigate';
import RequiredAsterisk from '../../component/form/required_asterisk';
import FreeToolsSubmenu from '../../component/section/publishers/FreeToolsSubmenu';

const currencies = [
    { code: 'USD', name: 'US Dollar' },
    { code: 'EUR', name: 'Euro' },
    { code: 'GBP', name: 'British Pound' },
    { code: 'JPY', name: 'Japanese Yen' },
    { code: 'AUD', name: 'Australian Dollar' },
    { code: 'CAD', name: 'Canadian Dollar' },
    { code: 'CHF', name: 'Swiss Franc' },
    { code: 'CNY', name: 'Chinese Yuan' },
    { code: 'SEK', name: 'Swedish Krona' },
    { code: 'NZD', name: 'New Zealand Dollar' },
    { code: 'MXN', name: 'Mexican Peso' },
    { code: 'SGD', name: 'Singapore Dollar' },
    { code: 'HKD', name: 'Hong Kong Dollar' },
    { code: 'NOK', name: 'Norwegian Krone' },
    { code: 'KRW', name: 'South Korean Won' },
    { code: 'TRY', name: 'Turkish Lira' },
    { code: 'RUB', name: 'Russian Ruble' },
    { code: 'INR', name: 'Indian Rupee' },
    { code: 'BRL', name: 'Brazilian Real' },
    { code: 'ZAR', name: 'South African Rand' },
    { code: 'PLN', name: 'Polish Zloty' },
    { code: 'DKK', name: 'Danish Krone' },
    { code: 'TWD', name: 'New Taiwan Dollar' },
    { code: 'THB', name: 'Thai Baht' },
    { code: 'MYR', name: 'Malaysian Ringgit' },
    { code: 'IDR', name: 'Indonesian Rupiah' },
    { code: 'PHP', name: 'Philippine Peso' },
    { code: 'VND', name: 'Vietnamese Dong' },
    { code: 'EGP', name: 'Egyptian Pound' },
    { code: 'AED', name: 'United Arab Emirates Dirham' },
];

const PublisherGamePricingPage = () => {
    const [costs, setCosts] = useState({ development: 0, manufacturing: 0, logistics: 0, administrative: 0, other: 0 });
    const [marketing, setMarketing] = useState({ reddit: { budget: 0, cpi: 0 }, facebook: { budget: 0, cpi: 0 }, tiktok: { budget: 0, cpi: 0 }, instagram: { budget: 0, cpi: 0 }, twitter: { budget: 0, cpi: 0 }, google: { budget: 0, cpi: 0 } });
    const [platforms, setPlatforms] = useState({ steam: 0, epic: 0, iphone: 0, android: 0, itch: 0 });
    const [influencers, setInfluencers] = useState({ budget: 0, anticipatedReach: 0, conversionRate: 0 });
    const [pr, setPR] = useState({ agencyFees: 0, mediaProduction: 0, eventCosts: 0, trackingCosts: 0, anticipatedConversions: 0 });
    const [misc, setMisc] = useState({ refundRate: 0, wishlistSubscribers: 0, conversionRate: 0, taxRate: 0, priceElasticity: 15.99 });
    const [profitMargin, setProfitMargin] = useState(20);
    const [currency, setCurrency] = useState('USD');
    const [agreedToDisclaimer, setAgreedToDisclaimer] = useState(false);
    const [price, setPrice] = useState(null);
    const [unitsNeeded, setUnitsNeeded] = useState(null);
    const [grossRevenue, setGrossRevenue] = useState(null);
    const [netRevenue, setNetRevenue] = useState(null);

    const calculatePrice = () => {
        // Calculate individual costs
        const totalCosts = Object.values(costs).reduce((a, b) => a + b, 0);
        const totalMarketingCost = Object.keys(marketing).reduce((acc, key) => acc + (marketing[key].budget || 0), 0);
        const totalInfluencerCost = influencers.budget;
        const totalPRCost = Object.values(pr).reduce((a, b) => a + b, 0);

        // Calculate platform costs as a percentage of revenue
        const totalPlatformCostRate = Object.keys(platforms).reduce((acc, key) => acc + (platforms[key] / 100), 0);

        // Calculate total installs from marketing and other conversions
        const totalInstalls = Object.keys(marketing).reduce((acc, key) => acc + (marketing[key].budget || 0) / (marketing[key].cpi || 1), 0);
        const wishlistConversions = (misc.wishlistSubscribers * (misc.conversionRate / 100)) || 0;
        const influencerConversions = (influencers.anticipatedReach * (influencers.conversionRate / 100)) || 0;
        const prConversions = pr.anticipatedConversions || 0;

        const totalEffectiveInstalls = totalInstalls + wishlistConversions + influencerConversions + prConversions;

        // Calculate revenue from installs
        const revenueFromInstalls = totalEffectiveInstalls * (misc.priceElasticity ?? 1);

        // Calculate the refund cost based on the refund rate and revenue
        const refundCost = (misc.refundRate / 100) * revenueFromInstalls;

        // Calculate total platform costs based on revenue
        const totalPlatformCost = totalPlatformCostRate * revenueFromInstalls;

        // Calculate total effective costs
        const effectiveCosts = (totalCosts + totalMarketingCost + totalInfluencerCost + totalPRCost + refundCost + totalPlatformCost) ? totalCosts + totalMarketingCost + totalInfluencerCost + totalPRCost + refundCost + totalPlatformCost : 100;

        // Calculate desired profit
        const desiredProfit = effectiveCosts * (profitMargin / 100);

        // Calculate final price per unit
        const finalPrice = (effectiveCosts + desiredProfit) / totalEffectiveInstalls;

        // Calculate the number of units needed to sell
        const finalUnitsNeeded = Math.ceil((effectiveCosts + desiredProfit) / finalPrice);

        // Calculate gross and net revenue
        const grossRev = totalEffectiveInstalls * finalPrice;
        const netRev = grossRev - effectiveCosts;

        // Set the calculated values
        setPrice(finalPrice.toFixed(2));
        setUnitsNeeded(finalUnitsNeeded.toLocaleString());
        setGrossRevenue(grossRev.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }));
        setNetRevenue(netRev.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }));
    };

    const currentUrl = typeof window !== 'undefined' ? window.location.href : '';

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Game Pricing Calculator for Premium Games | Glitch</title>
                <meta name="description" content="Calculate the optimal price for your premium game with our advanced pricing calculator. Take into account all relevant costs, desired profit margins, and more to maximize your revenue." />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="Game Pricing Calculator for Premium Games | Glitch" />
                <meta property="og:description" content="Calculate the optimal price for your premium game with our advanced pricing calculator. Take into account all relevant costs, desired profit margins, and more to maximize your revenue." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={currentUrl} />
                <meta property="og:image" content="https://www.glitch.fun/assets/images/publishers/gaming_calculator.webp" />
            </Helmet>
            <Header />
            <div className="container-fluid text-center text-white py-5" style={bannerStyle}>
                <br /><br />
                <h1 style={{ fontSize: "2.5rem" }}>Price vs Profitability <br /><br />Calculator For Gaming</h1>
            </div>
            <div className="container mt-5">
                <FreeToolsSubmenu />
            </div>
            <div className="container mt-5">

                <p className='lead'>This tool helps you calculate the optimal price for games that use a premium pricing model, where the game is sold for a one-time upfront cost. The calculated price is then compared to the costs to better understand the optimal marketing methods based on the game's price elasticity. All fields are optional.</p>

                <h3>Watch How-To Video</h3>
                <div className="embed-responsive embed-responsive-16by9 mb-4  border border-white border-2">
                    <iframe
                        className="embed-responsive-item"
                        src="https://www.youtube.com/embed/gR0W7ksh6zs"
                        allowFullScreen
                        title="YouTube video"
                        style={{ width: '100%', height: '500px' }}>
                    </iframe>
                </div>

                <hr className="my-4" />
                <section className="mb-4">
                    <h3 className="bg-primary text-white p-3 rounded">Currency</h3>
                    <div className="p-3 bg-dark text-white rounded">
                        <p>Select the currency for your calculations:</p>
                        <select className="form-select" value={currency} onChange={(e) => setCurrency(e.target.value)}>
                            {currencies.map((curr) => (
                                <option key={curr.code} value={curr.code}>{curr.name} ({curr.code})</option>
                            ))}
                        </select>
                    </div>
                </section>

                <hr className="my-4" />

                <section className="mb-4">
                    <h3 className="bg-primary text-white p-3 rounded">Price Elasicity (Average Competitor Price)</h3>
                    <div className="p-3 bg-dark text-white rounded">
                        <p>Set to the average price for a game of this genre and caliber, based on similar or competing games. This field is critical for determining pricing.</p>
                        <label>Price Elasticity Point<RequiredAsterisk /></label>
                        <input type="number" min="0" className="form-control" defaultValue={misc.priceElasticity} onChange={(e) => setMisc({ ...misc, priceElasticity: parseFloat(e.target.value) || 0 })} />
                    </div>
                </section>

                <hr className="my-4" />

                <section className="mb-4">
                    <h3 className="bg-primary text-white p-3 rounded">Operational Costs (Optional)</h3>
                    <div className="p-3 bg-dark text-white rounded">
                        <p>Enter your game development and associated costs here. All fields are optional.</p>
                        <div className="row g-3">
                            <div className="col-md-6">
                                <label>Development Costs</label>
                                <input type="number" min="0" className="form-control" onChange={(e) => setCosts({ ...costs, development: parseFloat(e.target.value) || 0 })} />
                            </div>
                            <div className="col-md-6">
                                <label>Manufacturing Costs</label>
                                <input type="number" min="0" className="form-control" onChange={(e) => setCosts({ ...costs, manufacturing: parseFloat(e.target.value) || 0 })} />
                            </div>
                            <div className="col-md-6">
                                <label>Logistics Costs</label>
                                <input type="number" min="0" className="form-control" onChange={(e) => setCosts({ ...costs, logistics: parseFloat(e.target.value) || 0 })} />
                            </div>
                            <div className="col-md-6">
                                <label>Administrative Costs</label>
                                <input type="number" min="0" className="form-control" onChange={(e) => setCosts({ ...costs, administrative: parseFloat(e.target.value) || 0 })} />
                            </div>
                            <div className="col-md-12">
                                <label>Other Costs</label>
                                <input type="number" min="0" className="form-control" onChange={(e) => setCosts({ ...costs, other: parseFloat(e.target.value) || 0 })} />
                            </div>
                        </div>
                    </div>
                </section>

                <hr className="my-4" />

                <section className="mb-4">
                    <h3 className="bg-primary text-white p-3 rounded">Advertising Costs (Optional)</h3>
                    <div className="p-3 bg-dark text-white rounded">
                        <p>Input your advertising budgets for various platforms along with the expected cost per installation (CPI). There are some average CPI ranges listed to help guide you. All fields are optional.</p>
                        <div className="row g-3">
                            <div className="col-md-6">
                                <h5>Facebook</h5>
                                <p>Average CPI: $1.50 to $3.00</p>
                                <label>Budget</label>
                                <input type="number" min="0" className="form-control mb-2" placeholder="Budget" onChange={(e) => setMarketing({ ...marketing, facebook: { ...marketing.facebook, budget: parseFloat(e.target.value) || 0 } })} />
                                <label>CPI</label>
                                <input type="number" min="0" className="form-control" placeholder="Cost Per Install" onChange={(e) => setMarketing({ ...marketing, facebook: { ...marketing.facebook, cpi: parseFloat(e.target.value) || 0 } })} />
                            </div>

                            <div className="col-md-6">
                                <h5>Google (YouTube)</h5>
                                <p>Average CPI: $1.00 to $2.50</p>
                                <label>Budget</label>
                                <input type="number" min="0" className="form-control mb-2" placeholder="Budget" onChange={(e) => setMarketing({ ...marketing, google: { ...marketing.google, budget: parseFloat(e.target.value) || 0 } })} />
                                <label>CPI</label>
                                <input type="number" min="0" className="form-control" placeholder="Cost Per Install" onChange={(e) => setMarketing({ ...marketing, google: { ...marketing.google, cpi: parseFloat(e.target.value) || 0 } })} />
                            </div>

                            <div className="col-md-6">
                                <h5>Instagram</h5>
                                <p>Average CPI: $2.00 to $4.00</p>
                                <label>Budget</label>
                                <input type="number" min="0" className="form-control mb-2" placeholder="Budget" onChange={(e) => setMarketing({ ...marketing, instagram: { ...marketing.instagram, budget: parseFloat(e.target.value) || 0 } })} />
                                <label>CPI</label>
                                <input type="number" min="0" className="form-control" placeholder="Cost Per Install" onChange={(e) => setMarketing({ ...marketing, instagram: { ...marketing.instagram, cpi: parseFloat(e.target.value) || 0 } })} />
                            </div>

                            <div className="col-md-6">
                                <h5>TikTok</h5>
                                <p>Average CPI: $1.50 to $3.50</p>
                                <label>Budget</label>
                                <input type="number" min="0" className="form-control mb-2" placeholder="Budget" onChange={(e) => setMarketing({ ...marketing, tiktok: { ...marketing.tiktok, budget: parseFloat(e.target.value) || 0 } })} />
                                <label>CPI</label>
                                <input type="number" min="0" className="form-control" placeholder="Cost Per Install" onChange={(e) => setMarketing({ ...marketing, tiktok: { ...marketing.tiktok, cpi: parseFloat(e.target.value) || 0 } })} />
                            </div>

                            <div className="col-md-6">
                                <h5>Reddit</h5>
                                <p>Average CPI: $1.00 to $2.50</p>
                                <label>Budget</label>
                                <input type="number" min="0" className="form-control mb-2" placeholder="Budget" onChange={(e) => setMarketing({ ...marketing, reddit: { ...marketing.reddit, budget: parseFloat(e.target.value) || 0 } })} />
                                <label>CPI</label>
                                <input type="number" min="0" className="form-control" placeholder="Cost Per Install" onChange={(e) => setMarketing({ ...marketing, reddit: { ...marketing.reddit, cpi: parseFloat(e.target.value) || 0 } })} />
                            </div>

                            <div className="col-md-6">
                                <h5>Twitter</h5>
                                <p>Average CPI: $1.00 to $2.50</p>
                                <label>Budget</label>
                                <input type="number" min="0" className="form-control mb-2" placeholder="Budget" onChange={(e) => setMarketing({ ...marketing, twitter: { ...marketing.twitter, budget: parseFloat(e.target.value) || 0 } })} />
                                <label>CPI</label>
                                <input type="number" min="0" className="form-control" placeholder="Cost Per Install" onChange={(e) => setMarketing({ ...marketing, twitter: { ...marketing.twitter, cpi: parseFloat(e.target.value) || 0 } })} />
                            </div>
                        </div>
                    </div>
                </section>

                <hr className="my-4" />

                <section className="mb-4">
                    <h3 className="bg-primary p-3 rounded">Influencer Costs (Optional)</h3>
                    <div className="p-3 bg-dark text-white rounded">
                        <p>Estimate the costs and conversions expected from influencer marketing. All fields are optional.</p>
                        <div className="row g-3">
                            <div className="col-md-4">
                                <label>Total Budget</label>
                                <input type="number" min="0" className="form-control" onChange={(e) => setInfluencers({ ...influencers, budget: parseFloat(e.target.value) || 0 })} />
                            </div>
                            <div className="col-md-4">
                                <label>Anticipated Reach</label>
                                <input type="number" min="0" className="form-control" onChange={(e) => setInfluencers({ ...influencers, anticipatedReach: parseFloat(e.target.value) || 0 })} />
                            </div>
                            <div className="col-md-4">
                                <label>Conversion Rate (%)</label>
                                <input type="number" min="0" className="form-control" onChange={(e) => setInfluencers({ ...influencers, conversionRate: parseFloat(e.target.value) || 0 })} />
                            </div>
                        </div>
                    </div>
                </section>

                <hr className="my-4" />

                <section className="mb-4">
                    <h3 className="bg-primary text-white p-3 rounded">PR Costs (Optional)</h3>
                    <div className="p-3 bg-dark text-white rounded">
                        <p>Input the costs and expected conversions from your PR efforts. All fields are optional.</p>
                        <div className="row g-3">
                            <div className="col-md-4">
                                <label>PR Agency Fees</label>
                                <input type="number" min="0" className="form-control" onChange={(e) => setPR({ ...pr, agencyFees: parseFloat(e.target.value) || 0 })} />
                            </div>
                            <div className="col-md-4">
                                <label>Media Production Costs</label>
                                <input type="number" min="0" className="form-control" onChange={(e) => setPR({ ...pr, mediaProduction: parseFloat(e.target.value) || 0 })} />
                            </div>
                            <div className="col-md-4">
                                <label>Event Costs</label>
                                <input type="number" min="0" className="form-control" onChange={(e) => setPR({ ...pr, eventCosts: parseFloat(e.target.value) || 0 })} />
                            </div>
                            <div className="col-md-4">
                                <label>Tracking and Analytics Costs</label>
                                <input type="number" min="0" className="form-control" onChange={(e) => setPR({ ...pr, trackingCosts: parseFloat(e.target.value) || 0 })} />
                            </div>
                            <div className="col-md-4">
                                <label>Anticipated Conversions</label>
                                <input type="number" min="0" className="form-control" onChange={(e) => setPR({ ...pr, anticipatedConversions: parseFloat(e.target.value) || 0 })} />
                            </div>
                        </div>
                    </div>
                </section>

                <hr className="my-4" />

                <section className="mb-4">
                    <h3 className="bg-primary text-white p-3 rounded">Platform & Distribution Costs (Optional)</h3>
                    <div className="p-3 bg-dark text-white rounded">
                        <p>Enter the distribution costs for each platform you're using. All fields are optional.</p>
                        <div className="row g-3">
                            {Object.keys(platforms).map((platform, idx) => (
                                <div key={idx} className="col-md-4">
                                    <label>{platform.charAt(0).toUpperCase() + platform.slice(1)} Fee (%)</label>
                                    <input type="number" min="0" className="form-control" defaultValue={platforms[platform]} onChange={(e) => setPlatforms({ ...platforms, [platform]: parseFloat(e.target.value) || 0 })} />
                                </div>
                            ))}
                        </div>
                    </div>
                </section>

                <hr className="my-4" />

                <section className="mb-4">
                    <h3 className="bg-primary text-white p-3 rounded">Miscellaneous Costs (Optional)</h3>
                    <div className="p-3 bg-dark text-white rounded">
                        <p>Fill in any miscellaneous costs and conversion rates that will impact your game's revenue. All fields are optional.</p>
                        <div className="row g-3">
                            <div className="col-md-4">
                                <label>Refund Rate (%)</label>
                                <input type="number" min="0" className="form-control" onChange={(e) => setMisc({ ...misc, refundRate: parseFloat(e.target.value) || 0 })} />
                            </div>
                            <div className="col-md-4">
                                <label>Wishlist Subscribers</label>
                                <input type="number" min="0" className="form-control" onChange={(e) => setMisc({ ...misc, wishlistSubscribers: parseFloat(e.target.value) || 0 })} />
                            </div>
                            <div className="col-md-4">
                                <label>Wishlist Conversion Rate (%)</label>
                                <input type="number" min="0" className="form-control" onChange={(e) => setMisc({ ...misc, conversionRate: parseFloat(e.target.value) || 0 })} />
                            </div>
                            <div className="col-md-4">
                                <label>Tax Rate (%)</label>
                                <input type="number" min="0" className="form-control" onChange={(e) => setMisc({ ...misc, taxRate: parseFloat(e.target.value) || 0 })} />
                            </div>
                            <div className="col-md-8">

                            </div>
                        </div>
                    </div>
                </section>

                <hr className="my-4" />

                <section className="mb-4">
                    <h3 className="bg-primary text-white p-3 rounded">Profit Margin</h3>
                    <div className="p-3 bg-dark text-white rounded">
                        <p>Set the desired profit margin for your game sales.</p>
                        <div className="row g-3">
                            <div className="col-md-12">
                                <label>Desired Profit Margin (%)</label>
                                <input type="number" min="0" className="form-control" defaultValue={profitMargin} onChange={(e) => setProfitMargin(parseFloat(e.target.value) || 0)} />
                            </div>
                        </div>
                    </div>
                </section>

                <hr className="my-4" />

                <div className="form-check mb-4">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="disclaimerCheck"
                        onChange={(e) => setAgreedToDisclaimer(e.target.checked)}
                    />
                    <label className="form-check-label text-white" htmlFor="disclaimerCheck">
                        I understand that these calculations are estimates and that the site is not responsible for any decisions made based on these results.
                    </label>
                </div>

                <section className="mb-5 text-center">
                    <button className="btn btn-primary mt-4" onClick={calculatePrice} disabled={!agreedToDisclaimer}>
                        <FontAwesomeIcon icon={faCalculator} /> Calculate Price
                    </button>

                    {price && (
                        <div className="mt-4">
                            <h3><FontAwesomeIcon icon={faDollarSign} /> Estimated Game Price: {price} {currency}</h3>
                            <h3><FontAwesomeIcon icon={faChartLine} /> Estimated Units Sold: {unitsNeeded}</h3>
                            <h3><FontAwesomeIcon icon={faDollarSign} /> Gross Revenue: {grossRevenue} {currency}</h3>
                            <h3><FontAwesomeIcon icon={faDollarSign} /> Net Revenue: {netRevenue} {currency}</h3>
                        </div>
                    )}
                </section>
            </div>
            <hr />
            <div className="container lead text-center mb-4 mt-4">
                <h4>Learn More About Performance-Based Influencer Marketing</h4>
                <div className="d-flex justify-content-center align-items-center flex-wrap">
                    <Link to={Navigate.publishersAIPage()} className="btn btn-outline-primary mx-2 mb-2">
                        <FontAwesomeIcon icon={faRobot} className="me-1" /> AI
                    </Link>
                    <Link to={Navigate.publishersCRMPage()} className="btn btn-outline-primary mx-2 mb-2">
                        <FontAwesomeIcon icon={faUsers} className="me-1" /> CRM
                    </Link>
                    <Link to={Navigate.publishersDatabasePage()} className="btn btn-outline-primary mx-2 mb-2">
                        <FontAwesomeIcon icon={faDatabase} className="me-1" /> Database
                    </Link>
                    <Link to={Navigate.publishersBenefitsPage()} className="btn btn-outline-primary mx-2 mb-2">
                        <span><FontAwesomeIcon icon={faThumbsUp} className="me-1" /> Benefits</span>
                    </Link>
                    <Link to={Navigate.publishersOptimizationPage()} className="btn btn-outline-primary mx-2 mb-2">
                        <span><FontAwesomeIcon icon={faTools} className="me-1" /> Optimization</span>
                    </Link>
                    <Link to={Navigate.publishersGamificationPage()} className="btn btn-outline-primary mx-2 mb-2">
                        <span><FontAwesomeIcon icon={faGamepad} className="me-1" /> Gamification</span>
                    </Link>
                    <Link to={Navigate.publishersAcquisitionPage()} className="btn btn-outline-primary mx-2 mb-2">
                        <span><FontAwesomeIcon icon={faDollarSign} className="me-1" /> Acquisition Costs</span>
                    </Link>
                    <Link to={Navigate.publishersPricingPage()} className="btn btn-outline-primary mx-2 mb-2">
                        <FontAwesomeIcon icon={faMoneyBill} className="me-1" /> Pricing
                    </Link>
                </div>
            </div>
            <Footer />
        </>
    );
};

const bannerStyle = {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("/assets/images/publishers/gaming_calculator.webp")`,
    height: '500px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontSize: '2.5rem',
    fontWeight: 'bold',
    textAlign: 'center',
    textShadow: '3px 3px 6px rgba(0, 0, 0, 0.9)'
};

export default PublisherGamePricingPage;
