import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalculator, faGamepad } from '@fortawesome/free-solid-svg-icons';
import Navigate from '../../../../../util/Navigate';

const FreeToolsSubmenu = () => {
  const location = useLocation();

  // Determine the current path to highlight the correct menu item
  const isActive = (path) => location.pathname === path;

  return (
    <div className="container-fluid py-3 submenu-container">
      <div className="d-flex justify-content-center flex-wrap">
        <ul className="nav nav-pills submenu-nav flex-column flex-md-row">
          <li className="nav-item">
            <Link
              to={Navigate.publishersToolsPricingPage()}
              className={`nav-link submenu-link ${isActive('/pricing-calculator') ? 'active' : ''}`}
            >
              <FontAwesomeIcon icon={faCalculator} className="me-2" /> Pricing Calculator
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to={Navigate.publishersToolsPRPage()}
              className={`nav-link submenu-link ${isActive('/gaming-pr') ? 'active' : ''}`}
            >
              <FontAwesomeIcon icon={faGamepad} className="me-2" /> Gaming PR
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default FreeToolsSubmenu;
