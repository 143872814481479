import React, { useEffect, useState } from 'react';
import Danger from '../../alerts/Danger';
import Glitch from 'glitch-javascript-sdk';
import Wysiwyg from '../../form/wysiwyg';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

function CampaignTargetingForm({ campaignData, setCampaignData, setCountries, setGenders, setTypes, errors }) {
  const [availableCountries, setAvailableCountries] = useState([]);
  const [availableGenders, setAvailableGenders] = useState([]);
  const [availableTypes, setAvailableTypes] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState(campaignData.countries || []);
  const [selectedGenders, setSelectedGenders] = useState(campaignData.genders || []);
  const [selectedTypes, setSelectedTypes] = useState(campaignData.types || []);

  useEffect(() => {
    Glitch.api.Utility.listCountries()
      .then(response => {
        setAvailableCountries(response.data.data);
      })
      .catch(error => {});

    Glitch.api.Utility.listGenders()
      .then(response => {
        setAvailableGenders(response.data.data);
      })
      .catch(error => {});

    Glitch.api.Utility.listTypes()
      .then(response => {
        setAvailableTypes(response.data.data);
      })
      .catch(error => {});
  }, []);

  const computeDifferences = (oldArray, newArray) => {
    const added = newArray.filter(newItem => !oldArray.some(oldItem => oldItem.id === newItem.id));
    const removed = oldArray.filter(oldItem => !newArray.some(newItem => newItem.id === oldItem.id));
    return { added, removed };
  };

  const handleTypesChange = selected => {
    const { added, removed } = computeDifferences(selectedTypes, selected);

    setSelectedTypes(selected);
    setTypes(selected);

    setCampaignData(prevData => ({
      ...prevData,
      types: selected
    }));

    if (campaignData.id) {
      added.forEach(type => {
        Glitch.api.Campaigns.addType(campaignData.id, { type_id: type.id })
          .then(response => {})
          .catch(error => {
            console.error('Error adding type', error);
          });
      });

      removed.forEach(type => {
        Glitch.api.Campaigns.removeType(campaignData.id, type.id)
          .then(response => {})
          .catch(error => {
            console.error('Error removing type', error);
          });
      });
    }
  };

  const handleCountriesChange = selected => {
    const { added, removed } = computeDifferences(selectedCountries, selected);

    setSelectedCountries(selected);
    setCountries(selected);

    setCampaignData(prevData => ({
      ...prevData,
      countries: selected
    }));

    if (campaignData.id) {
      added.forEach(country => {
        Glitch.api.Campaigns.addCountry(campaignData.id, { country_id: country.id })
          .then(response => {})
          .catch(error => {
            console.error('Error adding country', error);
          });
      });

      removed.forEach(country => {
        Glitch.api.Campaigns.removeCountry(campaignData.id, country.id)
          .then(response => {})
          .catch(error => {
            console.error('Error removing country', error);
          });
      });
    }
  };

  const handleGendersChange = selected => {
    const { added, removed } = computeDifferences(selectedGenders, selected);

    setSelectedGenders(selected);
    setGenders(selected);

    setCampaignData(prevData => ({
      ...prevData,
      genders: selected
    }));

    if (campaignData.id) {
      added.forEach(gender => {
        Glitch.api.Campaigns.addGender(campaignData.id, { gender_id: gender.id })
          .then(response => {})
          .catch(error => {
            console.error('Error adding gender', error);
          });
      });

      removed.forEach(gender => {
        Glitch.api.Campaigns.removeGender(campaignData.id, gender.id)
          .then(response => {})
          .catch(error => {
            console.error('Error removing gender', error);
          });
      });
    }
  };

  const handleAgeMin = selectedValue => {
    setCampaignData({ ...campaignData, ['target_age_minimum']: selectedValue.target.value });
  };

  const handleAgeMax = selectedValue => {
    setCampaignData({ ...campaignData, ['target_age_maximum']: selectedValue.target.value });
  };

  const handleWysiwigInputChange = (name, value) => {
    setCampaignData(campaignData => ({ ...campaignData, [name]: value }));
  };

  return (
    <div className="container mt-4">
      <div className="card">
        <div className="card-header bg-secondary">
          <h3>
            <i className="fas fa-info-circle mr-2"></i> Targeting Information
          </h3>
        </div>
        <div className="card-body">
          <p className="lead">
            Define the target audience for your campaign based on who you want it marketed to. This
            information will be provided to the influencer to ensure a suitable match for marketing
            purposes.
          </p>

          <hr />

          <form>
            {createTextAreaField(
              'target_audience',
              'Target Audience (optional)',
              'Describe potential target audiences to give the influencer a clear understanding of the people you aim to reach with your marketing efforts.',
              errors
            )}

            {/* Type Section */}
            <div>
              <h4 className="text-black">Game Type(s) (Optional)</h4>
              <p>
                Select all the types that your game falls under to match with the correct audiences.
              </p>
              <div className="mb-3">
                <Typeahead
                  id="types-typeahead"
                  labelKey="name"
                  multiple
                  options={availableTypes}
                  placeholder="Select Multiple Types"
                  selected={selectedTypes}
                  onChange={handleTypesChange}
                />
              </div>
            </div>

            {/* Country Section */}
            <div>
              <h4 className="text-black">Countries (Optional)</h4>
              <p>
                Select the countries you wish to target with this campaign. You may choose multiple
                countries.
              </p>
              <div className="mb-3">
                <Typeahead
                  id="countries-typeahead"
                  labelKey="name"
                  multiple
                  options={availableCountries}
                  placeholder="Select Countries"
                  selected={selectedCountries}
                  onChange={handleCountriesChange}
                />
              </div>
            </div>

            {/* Gender Section */}
            <div>
              <h4 className="text-black">Genders (Optional)</h4>
              <p>
                Select the genders you aim to target with this campaign. Multiple genders can be
                selected.
              </p>
              <div className="mb-3">
                <Typeahead
                  id="genders-typeahead"
                  labelKey="name"
                  multiple
                  options={availableGenders}
                  placeholder="Select Genders"
                  selected={selectedGenders}
                  onChange={handleGendersChange}
                />
              </div>
            </div>

            {/* Age Range */}
            <h4 className="text-black">Age Range (Optional)</h4>
            <p>Specify the optional age range for your target audience for this campaign.</p>
            <div className="mb-3">
              <label htmlFor={'target_age_minimum'}>Min Age (optional)</label>
              <select
                className="form-control"
                name="target_age_minimum"
                id="target_age_minimum"
                onChange={handleAgeMin}
                value={campaignData.target_age_minimum}
              >
                <option value={''}>Select Min Age (Optional)</option>
                {Array.from({ length: 95 }, (_, i) => i + 5).map(age => (
                  <option key={age} value={age}>
                    {age}
                  </option>
                ))}
              </select>
              <p className="small">
                Set a minimum age for the target audience of this campaign.
              </p>
            </div>

            <div className="mb-3">
              <label htmlFor={'target_age_maximum'}>Max Age (optional)</label>
              <select
                className="form-control"
                name="target_age_maximum"
                id="target_age_maximum"
                onChange={handleAgeMax}
                value={campaignData.target_age_maximum}
              >
                <option value={''}>Select Max Age (Optional)</option>
                {Array.from({ length: 95 }, (_, i) => i + 5).map(age => (
                  <option key={age} value={age}>
                    {age}
                  </option>
                ))}
              </select>
              <p className="small">
                Set a maximum age for the target audience of this campaign.
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );

  function createTextAreaField(name, label, description, errors) {
    return (
      <>
        <div className="mb-3">
          <label htmlFor={name}>{label}</label>
          <p className="small">{description}</p>

          <Wysiwyg
            children={campaignData[name] || ''}
            name={name}
            id={name}
            onChange={value => {
              handleWysiwigInputChange(name, value);
            }}
          />
        </div>
        {errors &&
          errors[name] &&
          errors[name].map(function (name, index) {
            return <Danger message={name} key={index} />;
          })}
      </>
    );
  }
}

export default CampaignTargetingForm;
