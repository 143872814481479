import React, { useState, useCallback, useEffect } from 'react';
import Glitch from 'glitch-javascript-sdk';
import Danger from '../../alerts/Danger';
import Data from '../../../../../util/Data';
import Cropper from 'react-easy-crop';
import getCroppedImg from './getCroppedImg'; // You'll create this helper function
import RequiredAsterisk from '../../form/required_asterisk';
import Wysiwyg from '../../form/wysiwyg';
import Select from '../../form/select'; // Assuming you have a Select component

const GameTitleForm = ({
    gameTitle,
    campaignData,
    onUpdate,
    setGameTitle,
    onMainImageUpdate,
    onBannerImageUpdate,
    setMainImageBlob,
    setBannerImageBlob,
    externalGameData,
    errors,
}) => {
    const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);
    const [mainImage, setMainImage] = useState(null);
    const [bannerImage, setBannerImage] = useState(null);

    const [gameData, setGameData] = useState(null);

    const [cropMain, setCropMain] = useState({ x: 0, y: 0 });
    const [cropBanner, setCropBanner] = useState({ x: 0, y: 0 });
    const [zoomMain, setZoomMain] = useState(1);
    const [zoomBanner, setZoomBanner] = useState(1);
    const [croppedAreaPixelsMain, setCroppedAreaPixelsMain] = useState(null);
    const [croppedAreaPixelsBanner, setCroppedAreaPixelsBanner] = useState(null);

    const [croppedImageSrc, setCroppedImageSrc] = useState(null);
    const [croppedBannerImageSrc, setBannerCroppedImageSrc] = useState(null);

    const [showMainCropper, setShowMainCropper] = useState(false);
    const [showBannerCropper, setShowBannerCropper] = useState(false);

    const PROMOTION_TYPE_GAME = 0;
    const PROMOTION_TYPE_EVENT = 1;

    useEffect(() => {
        setGameData(gameTitle);

        if (externalGameData && externalGameData?.header_image) {
            fetchImage(externalGameData.header_image, setMainImage, setCropMain, setZoomMain, setShowMainCropper);
        }
        if (externalGameData && externalGameData?.capsule_image) {
            fetchImage(externalGameData.capsule_image, setBannerImage, setCropBanner, setZoomBanner, setShowBannerCropper);
        }
    }, []);

    const handleWysiwigInputChange = (name, value) => {
        setGameTitle((gameTitle) => ({ ...gameTitle, [name]: value }));
    };

    const handleChange = (e) => {
        const { name, value, type } = e.target;

        // Handle boolean values for checkboxes and radio buttons
        let updatedValue = value;
        if (type === 'checkbox') {
            updatedValue = e.target.checked;
        } else if (name === 'is_virtual') {
            updatedValue = value === '1' ? true : false;
        }

        setGameTitle({ ...gameTitle, [name]: updatedValue });
    };

    const toggleAdditionalInfo = () => {
        setShowAdditionalInfo(!showAdditionalInfo);
    };

    const onCropCompleteMain = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixelsMain(croppedAreaPixels);
    }, []);

    const onCropCompleteBanner = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixelsBanner(croppedAreaPixels);
    }, []);

    const showCroppedImage = async (imageSrc, croppedAreaPixels, setImage, name) => {
        try {
            const croppedImageBlob = await getCroppedImg(imageSrc, croppedAreaPixels);
            const croppedImageUrl = URL.createObjectURL(croppedImageBlob);

            if (gameTitle.id && name === 'mainImage') {
                setCroppedImageSrc(croppedImageUrl);
                await Glitch.api.Titles.uploadMainImageBlob(gameTitle.id, croppedImageBlob);
            } else if (gameTitle.id && name === 'bannerImage') {
                setBannerCroppedImageSrc(croppedImageUrl);
                await Glitch.api.Titles.uploadBannerImageFile(gameTitle.id, croppedImageBlob);
            } else if (!gameTitle.id && name === 'mainImage') {
                setCroppedImageSrc(croppedImageUrl);
                setMainImageBlob(croppedImageBlob);
            } else if (!gameTitle.id && name === 'bannerImage') {
                setBannerCroppedImageSrc(croppedImageUrl);
                setBannerImageBlob(croppedImageBlob);
            }
            setImage(null);
        } catch (e) {
            console.error(e);
        }
    };

    const handleImageChange = (e, imageSetter, cropSetter, zoomSetter, setShowCropper) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                imageSetter(reader.result);
                cropSetter({ x: 0, y: 0 });
                zoomSetter(1);
                setShowCropper(true);
            };
        }
    };

    const fetchImage = async (url, imageSetter, cropSetter, zoomSetter, setShowCropper) => {
        const proxyUrl = `https://api.glitch.fun/api/images/proxy?url=${encodeURIComponent(url)}`;
        try {
            const response = await fetch(proxyUrl);
            const blob = await response.blob();
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                imageSetter(reader.result);
                cropSetter({ x: 0, y: 0 });
                zoomSetter(1);
                setShowCropper(true);
            };
        } catch (error) {
            console.error('Failed to fetch image:', error);
        }
    };

    const timezones = [
        { label: 'UTC', value: 'UTC' },
        { label: 'America/New_York', value: 'America/New_York' },
        { label: 'America/Chicago', value: 'America/Chicago' },
        { label: 'America/Denver', value: 'America/Denver' },
        { label: 'America/Los_Angeles', value: 'America/Los_Angeles' },
        { label: 'Europe/London', value: 'Europe/London' },
        { label: 'Europe/Paris', value: 'Europe/Paris' },
        { label: 'Asia/Tokyo', value: 'Asia/Tokyo' },
        { label: 'Australia/Sydney', value: 'Australia/Sydney' },
        // Add more as needed
    ];

    return (
        <div className="container mt-4">
            {(!campaignData.promotion_type || campaignData.promotion_type == PROMOTION_TYPE_GAME) && (
                <form>
                    {/* Existing game form */}
                    <div className="card mb-3">
                        <div className="card-header bg-primary text-white">
                            <h3>
                                <i className="fas fa-gamepad mr-2"></i> Game Title
                            </h3>
                        </div>
                        <div className="card-body">
                            <p className="lead">
                                Enter information about the game title you want influencers to promote. Please fill out
                                as much information as possible to excite the potential creator(s) you might be working
                                with.
                            </p>
                            <hr />
                            <div className="row">
                            {createInput(
                                    'Name',
                                    'name',
                                    gameTitle?.name,
                                    handleChange,
                                    'text',
                                    'fas fa-signature',
                                    errors,
                                    'Enter the name of the game.',
                                    true
                                )}
                            </div>
                            <div className="row">
                                
                                {createInput(
                                    'Platform Compatibility (Optional)',
                                    'platform_compatibility',
                                    gameTitle?.platform_compatibility,
                                    handleChange,
                                    'text',
                                    'fab fa-steam-symbol',
                                    errors,
                                    'Enter what platforms this game is for, ie: Playstation 5, PC, Xbox, etc'
                                )}
                                {createInput(
                                    'Age Rating (Optional)',
                                    'age_rating',
                                    gameTitle?.age_rating,
                                    handleChange,
                                    'text',
                                    'fas fa-child',
                                    errors,
                                    'Enter domestic and/or global age ratings for the game.'
                                )}
                            </div>
                            <div className="row">    
                                {createInput(
                                    'Developer (Optional)',
                                    'developer',
                                    gameTitle?.developer,
                                    handleChange,
                                    'text',
                                    'fas fa-code-branch',
                                    errors,
                                    'Enter the development studios for the game.'
                                )}

                                 {createInput(
                                    'Publisher (Optional)',
                                    'publisher',
                                    gameTitle?.publisher,
                                    handleChange,
                                    'text',
                                    'fas fa-briefcase',
                                    errors,
                                    'Enter the publishers.'
                                )}
                            </div>
                            <div className="row">
                               
                                {createInput(
                                    'Release Date (Optional)',
                                    'release_date',
                                    gameTitle?.release_date,
                                    handleChange,
                                    'date',
                                    'fas fa-calendar-alt',
                                    errors,
                                    'Enter the date the game will be released.'
                                )}
                                 {createInput(
                                    'Availability (Optional)',
                                    'availability',
                                    gameTitle?.availability,
                                    handleChange,
                                    'text',
                                    'fas fa-store',
                                    errors,
                                    'Set the availiability of the game'
                                )}
                            </div>
                            <div className="row">
                                {createInput(
                                    'Pricing (Optional)',
                                    'pricing',
                                    gameTitle?.pricing,
                                    handleChange,
                                    'number',
                                    'fas fa-tag',
                                    errors,
                                    'Enter the price for the game.'
                                )}
                                {createInput(
                                    'Pricing Currency (Optional)',
                                    'pricing_currency',
                                    gameTitle?.pricing_currency || 'USD',
                                    handleChange,
                                    'text',
                                    'fas fa-dollar-sign',
                                    errors,
                                    'Set the pricing currency for the country of the game'
                                )}
                            </div>
                            <div className="row">
                                {createInput(
                                    'Multiplayer Options (Optional)',
                                    'multiplayer_options',
                                    gameTitle?.multiplayer_options,
                                    handleChange,
                                    'text',
                                    'fas fa-users',
                                    errors,
                                    'Enter any mutliplayer options of the game.'
                                )}
                                 {createInput(
                                    'Google Playstore URL (Optional)',
                                    'android_url',
                                    gameTitle?.android_url,
                                    handleChange,
                                    'url',
                                    'fab fa-android',
                                    errors,
                                    'Enter the url for the Google Play Store download page of the game'
                                )}
                               
                            </div>
                            <div className="row">
                                {createInput(
                                    'Website URL (Optional)',
                                    'website_url',
                                    gameTitle?.website_url,
                                    handleChange,
                                    'url',
                                    'fas fa-globe',
                                    errors,
                                     'Enter the url for the website of the game'
                                )}
                                {createInput(
                                    'Steam URL (Optional)',
                                    'steam_url',
                                    gameTitle?.steam_url,
                                    handleChange,
                                    'url',
                                    'fab fa-steam',
                                    errors,
                                     'Enter the url for the Steam download page of the game'
                                )}
                            </div>
                            <div className="row">
                                {createInput(
                                    'Itch.io URL (Optional)',
                                    'itch_url',
                                    gameTitle?.itch_url,
                                    handleChange,
                                    'url',
                                    'fas fa-link',
                                    errors,
                                     'Enter the url for the Itch store of the game'
                                )}
                                {createInput(
                                    'Apple Store URL (Optional)',
                                    'apple_url',
                                    gameTitle?.apple_url,
                                    handleChange,
                                    'url',
                                    'fab fa-apple',
                                    errors,
                                     'Enter the url for the Apple Store download page of the game.'
                                )}
                            </div>

                            {createTextarea(
                                'Short Description',
                                'short_description',
                                gameTitle?.short_description,
                                handleChange,
                                errors,
                                'Enter a 1 paragraph brief short description about the game.',
                                true
                            )}
                            {createTextarea(
                                'Long Description (Optional)',
                                'long_description',
                                gameTitle?.long_description,
                                handleChange,
                                errors,
                                'Enter a longer more in-depth description about the game that can be multiple paragraphs.'
                            )}
                        </div>

                        <div className="card-header bg-secondary text-white">
                            Upload Game Images
                        </div>
                        <div className="card-body">
                            <div className="form-group">
                                <label htmlFor="mainImage">Main Image</label>
                                <p className="small">Recommended dimensions: 374x448 pixels.</p>
                                <input
                                    type="file"
                                    accept="image/*"
                                    className="form-control-file"
                                    id="mainImage"
                                    onChange={(e) =>
                                        handleImageChange(
                                            e,
                                            setMainImage,
                                            setCropMain,
                                            setZoomMain,
                                            setShowMainCropper
                                        )
                                    }
                                />
                                {croppedImageSrc && (
                                    <div className="mt-3">
                                        <p>Cropped Image:</p>
                                        <img src={croppedImageSrc} alt="Cropped" className="img-fluid w-50" />
                                    </div>
                                )}
                                {gameTitle.image_main && !croppedImageSrc && (
                                    <div className="mt-3">
                                        <p>Current Main Image:</p>
                                        <img src={gameTitle.image_main} alt="Current Main Image" className="img-fluid w-50" />
                                    </div>
                                )}
                            </div>
                            <div className="form-group">
                                <label htmlFor="bannerImage">Banner Image</label>
                                <p className="small">Recommended dimensions: 3840x1240 pixels.</p>
                                <input
                                    type="file"
                                    accept="image/*"
                                    className="form-control-file"
                                    id="bannerImage"
                                    onChange={(e) =>
                                        handleImageChange(
                                            e,
                                            setBannerImage,
                                            setCropBanner,
                                            setZoomBanner,
                                            setShowBannerCropper
                                        )
                                    }
                                />
                                {croppedBannerImageSrc && (
                                    <div className="mt-3">
                                        <p>Cropped Image:</p>
                                        <img src={croppedBannerImageSrc} alt="Cropped" className="img-fluid w-50" />
                                    </div>
                                )}
                                {gameTitle.image_banner && !croppedBannerImageSrc && (
                                    <div className="mt-3">
                                        <p>Current Banner Image:</p>
                                        <img
                                            src={gameTitle.image_banner}
                                            alt="Current Banner Image"
                                            className="img-fluid w-50"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="card-header bg-secondary text-white">Product Videos</div>
                        <div className="card-body">
                            {createInput(
                                'Youtube/Vimeo Video Url',
                                'video_url',
                                gameTitle?.video_url,
                                handleChange,
                                'url',
                                'fas fa-file-video',
                                errors,
                                'Enter a Youtube or Vimeo video url to use that as a promo video.'
                            )}
                        </div>

                        <div className="card-header bg-secondary text-white d-flex justify-content-between align-items-center">
                            <span>
                                <i className="fas fa-book-open mr-2"></i>Additional Game Information
                            </span>
                            <button
                                type="button"
                                className="btn btn-light btn-sm"
                                onClick={toggleAdditionalInfo}
                            >
                                {showAdditionalInfo ? 'Hide' : 'Show'}
                            </button>
                        </div>
                        {showAdditionalInfo && (
                            <div className="card-body">
                                {createTextarea(
                                    'Gameplay Mechanics',
                                    'gameplay_mechanics',
                                    gameTitle?.gameplay_mechanics,
                                    handleChange,
                                    errors
                                )}
                                {createTextarea(
                                    'Narrative Setting',
                                    'narrative_setting',
                                    gameTitle?.narrative_setting,
                                    handleChange,
                                    errors
                                )}
                                {createTextarea(
                                    'Visual & Audio Style',
                                    'visual_audio_style',
                                    gameTitle?.visual_audio_style,
                                    handleChange,
                                    errors
                                )}
                                {createTextarea(
                                    'DLC & Expansion Info',
                                    'dlc_expansion_info',
                                    gameTitle?.dlc_expansion_info,
                                    handleChange,
                                    errors
                                )}
                                {createTextarea(
                                    'System Requirements',
                                    'system_requirements',
                                    gameTitle?.system_requirements,
                                    handleChange,
                                    errors
                                )}
                                {createTextarea(
                                    'Critical Reception',
                                    'critical_reception',
                                    gameTitle?.critical_reception,
                                    handleChange,
                                    errors
                                )}
                            </div>
                        )}
                    </div>
                </form>
            )}

            {campaignData.promotion_type == PROMOTION_TYPE_EVENT && (
                <form>
                    {/* Event form */}
                    <div className="card mb-3">
                        <div className="card-header bg-primary text-white">
                            <h3>
                                <i className="fas fa-calendar-alt mr-2"></i> Event Details
                            </h3>
                        </div>
                        <div className="card-body">
                            <p className="lead">
                                Enter information about the event you want influencers to promote. Please fill out as
                                much information as possible to excite the potential creator(s) you might be working
                                with.
                            </p>
                            <hr />
                            <div className="row">
                                {createInput(
                                    'Event Name',
                                    'name',
                                    gameTitle?.name,
                                    handleChange,
                                    'text',
                                    'fas fa-signature',
                                    errors,
                                    'Enter the name of the event.',
                                    true
                                )}
                                {createTextarea(
                                    'Short Description',
                                    'short_description',
                                    gameTitle?.short_description,
                                    handleChange,
                                    errors,
                                    'Enter a brief short description about the event.',
                                    true
                                )}
                            </div>
                            <div className="row">
                                {createTextarea(
                                    'Long Description (Optional)',
                                    'long_description',
                                    gameTitle?.long_description,
                                    handleChange,
                                    errors,
                                    'Enter a longer, more in-depth description about the event.'
                                )}
                            </div>
                            <div className="form-group mb-3">
                                <label>
                                    <i className="fas fa-laptop mr-2"></i> &nbsp;Is the event virtual?{' '}
                                    <RequiredAsterisk />
                                </label>
                                <div>
                                    <label className="mr-2">
                                        <input
                                            type="radio"
                                            name="is_virtual"
                                            value="1"
                                            checked={
                                                gameTitle.is_virtual === true || gameTitle.is_virtual === '1'
                                            }
                                            onChange={handleChange}
                                        />{' '}
                                        Yes
                                    </label>
                                    <label className="mr-2">
                                        <input
                                            type="radio"
                                            name="is_virtual"
                                            value="0"
                                            checked={
                                                gameTitle.is_virtual === false || gameTitle.is_virtual === '0'
                                            }
                                            onChange={handleChange}
                                        />{' '}
                                        No
                                    </label>
                                </div>
                                {errors && errors['is_virtual'] && errors['is_virtual'].map((message, index) => (
                                    <Danger message={message} key={index} />
                                ))}
                            </div>

                            {(gameTitle.is_virtual === false || gameTitle.is_virtual === '0') && (
                                <>
                                    <div className="row">
                                        {createInput(
                                            'Address Line 1',
                                            'address_line_1',
                                            gameTitle?.address_line_1,
                                            handleChange,
                                            'text',
                                            'fas fa-address-card',
                                            errors,
                                            "Enter the first line of the event's physical address.",
                                            true
                                        )}
                                        {createInput(
                                            'Address Line 2',
                                            'address_line_2',
                                            gameTitle?.address_line_2,
                                            handleChange,
                                            'text',
                                            'fas fa-address-card',
                                            errors,
                                            "Enter the second line of the event's physical address."
                                        )}
                                    </div>
                                    <div className="row">
                                        {createInput(
                                            'City',
                                            'locality',
                                            gameTitle?.locality,
                                            handleChange,
                                            'text',
                                            'fas fa-city',
                                            errors,
                                            'Enter the city where the event is held.',
                                            true
                                        )}
                                        {createInput(
                                            'State/Province',
                                            'province',
                                            gameTitle?.province,
                                            handleChange,
                                            'text',
                                            'fas fa-map',
                                            errors,
                                            'Enter the state or province of the event location.',
                                            true
                                        )}
                                    </div>
                                    <div className="row">
                                        {createInput(
                                            'Postal Code',
                                            'postal_code',
                                            gameTitle?.postal_code,
                                            handleChange,
                                            'text',
                                            'fas fa-envelope',
                                            errors,
                                            'Enter the postal code of the event location.',
                                            true
                                        )}
                                        {createInput(
                                            'Country',
                                            'country',
                                            gameTitle?.country,
                                            handleChange,
                                            'text',
                                            'fas fa-flag',
                                            errors,
                                            'Enter the country where the event is held.',
                                            true
                                        )}
                                    </div>
                                </>
                            )}

                            <div className="row">
                                {createInput(
                                    'Start Date and Time',
                                    'start_date',
                                    gameTitle?.start_date,
                                    handleChange,
                                    'datetime-local',
                                    'fas fa-calendar-alt',
                                    errors,
                                    'Enter the start date and time of the event.',
                                    true
                                )}
                                {createInput(
                                    'End Date and Time',
                                    'end_date',
                                    gameTitle?.end_date,
                                    handleChange,
                                    'datetime-local',
                                    'fas fa-calendar-alt',
                                    errors,
                                    'Enter the end date and time of the event.',
                                    true
                                )}
                            </div>

                            <div className="form-group mb-3">
                                <label htmlFor="timezone">
                                    <i className="fas fa-clock mr-2"></i> &nbsp;Timezone <RequiredAsterisk />
                                </label>
                                <Select
                                    name="timezone"
                                    className="form-select"
                                    onChange={handleChange}
                                    value={gameTitle.timezone || ''}
                                >
                                    <option value="">Select a Timezone</option>
                                    {timezones.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </Select>
                                <p className="small">Select the timezone for the event.</p>
                                {errors && errors['timezone'] && errors['timezone'].map((message, index) => (
                                    <Danger message={message} key={index} />
                                ))}
                            </div>
                        </div>

                        <div className="card-header bg-secondary text-white">Upload Event Images</div>
                        <div className="card-body">
                            {/* Image upload sections similar to game form */}
                            <div className="form-group">
                                <label htmlFor="mainImage">Main Image</label>
                                <p className="small">Recommended dimensions: 374x448 pixels.</p>
                                <input
                                    type="file"
                                    accept="image/*"
                                    className="form-control-file"
                                    id="mainImage"
                                    onChange={(e) =>
                                        handleImageChange(
                                            e,
                                            setMainImage,
                                            setCropMain,
                                            setZoomMain,
                                            setShowMainCropper
                                        )
                                    }
                                />
                                {croppedImageSrc && (
                                    <div className="mt-3">
                                        <p>Cropped Image:</p>
                                        <img src={croppedImageSrc} alt="Cropped" className="img-fluid w-50" />
                                    </div>
                                )}
                                {gameTitle.image_main && !croppedImageSrc && (
                                    <div className="mt-3">
                                        <p>Current Main Image:</p>
                                        <img src={gameTitle.image_main} alt="Current Main Image" className="img-fluid w-50" />
                                    </div>
                                )}
                            </div>
                            <div className="form-group">
                                <label htmlFor="bannerImage">Banner Image</label>
                                <p className="small">Recommended dimensions: 3840x1240 pixels.</p>
                                <input
                                    type="file"
                                    accept="image/*"
                                    className="form-control-file"
                                    id="bannerImage"
                                    onChange={(e) =>
                                        handleImageChange(
                                            e,
                                            setBannerImage,
                                            setCropBanner,
                                            setZoomBanner,
                                            setShowBannerCropper
                                        )
                                    }
                                />
                                {croppedBannerImageSrc && (
                                    <div className="mt-3">
                                        <p>Cropped Image:</p>
                                        <img src={croppedBannerImageSrc} alt="Cropped" className="img-fluid w-50" />
                                    </div>
                                )}
                                {gameTitle.image_banner && !croppedBannerImageSrc && (
                                    <div className="mt-3">
                                        <p>Current Banner Image:</p>
                                        <img
                                            src={gameTitle.image_banner}
                                            alt="Current Banner Image"
                                            className="img-fluid w-50"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="card-header bg-secondary text-white">Event Video</div>
                        <div className="card-body">
                            {createInput(
                                'Youtube/Vimeo Video Url',
                                'video_url',
                                gameTitle?.video_url,
                                handleChange,
                                'url',
                                'fas fa-file-video',
                                errors,
                                'Enter a Youtube or Vimeo video url to use that as a promo video.'
                            )}
                        </div>
                    </div>
                </form>
            )}

            {/* Main Image Cropper Modal */}
            {showMainCropper && (
                <div className="modal show" style={{ display: 'block' }} tabIndex="-1">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title text-black">Crop Main Image</h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    aria-label="Close"
                                    onClick={() => setShowMainCropper(false)}
                                ></button>
                            </div>
                            <div className="modal-body">
                                <div
                                    className="crop-container"
                                    style={{ position: 'relative', width: '100%', height: '400px' }}
                                >
                                    <Cropper
                                        image={mainImage}
                                        crop={cropMain}
                                        zoom={zoomMain}
                                        aspect={374 / 448}
                                        onCropChange={setCropMain}
                                        onZoomChange={setZoomMain}
                                        onCropComplete={onCropCompleteMain}
                                    />
                                </div>
                                <div className="slider-container mt-3">
                                    <label>Zoom</label>
                                    <input
                                        type="range"
                                        min={1}
                                        max={3}
                                        step={0.1}
                                        value={zoomMain}
                                        onChange={(e) => setZoomMain(e.target.value)}
                                        className="form-range"
                                    />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={() => setShowMainCropper(false)}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => {
                                        showCroppedImage(mainImage, croppedAreaPixelsMain, setMainImage, 'mainImage');
                                        setShowMainCropper(false);
                                    }}
                                >
                                    Crop Image
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Banner Image Cropper Modal */}
            {showBannerCropper && (
                <div className="modal show" style={{ display: 'block' }} tabIndex="-1">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title text-black">Crop Banner Image</h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    aria-label="Close"
                                    onClick={() => setShowBannerCropper(false)}
                                ></button>
                            </div>
                            <div className="modal-body">
                                <div
                                    className="crop-container"
                                    style={{ position: 'relative', width: '100%', height: '400px' }}
                                >
                                    <Cropper
                                        image={bannerImage}
                                        crop={cropBanner}
                                        zoom={zoomBanner}
                                        aspect={3840 / 1240}
                                        onCropChange={setCropBanner}
                                        onZoomChange={setZoomBanner}
                                        onCropComplete={onCropCompleteBanner}
                                    />
                                </div>
                                <div className="slider-container mt-3">
                                    <label>Zoom</label>
                                    <input
                                        type="range"
                                        min={1}
                                        max={3}
                                        step={0.1}
                                        value={zoomBanner}
                                        onChange={(e) => setZoomBanner(e.target.value)}
                                        className="form-range"
                                    />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={() => setShowBannerCropper(false)}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => {
                                        showCroppedImage(
                                            bannerImage,
                                            croppedAreaPixelsBanner,
                                            setBannerImage,
                                            'bannerImage'
                                        );
                                        setShowBannerCropper(false);
                                    }}
                                >
                                    Crop Image
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );

    function createInput(
        label,
        name,
        value,
        handleChange,
        type = 'text',
        icon,
        errors,
        description = '',
        required = false
    ) {
        return (
            <div className="col">
                <div className="form-group mb-3">
                    <label htmlFor={name}>
                        <i className={`${icon} mr-2`}></i> &nbsp;{label} {required ? <RequiredAsterisk /> : ''}
                    </label>
                    <p className="small">{description}</p>
                    <input
                        type={type}
                        className="form-control"
                        id={name}
                        name={name}
                        value={value || ''}
                        onChange={handleChange}
                    />
                    
                </div>
                {errors && errors[name] && errors[name].map((message, index) => (
                    <Danger message={message} key={index} />
                ))}
            </div>
        );
    }

    function createTextarea(label, name, value, handleChange, errors, description, required = false) {
        return (
            <div className="col-md-12">
                <div className="mb-3">
                    <label htmlFor={name}>
                        {label} {required && <RequiredAsterisk />}
                    </label>
                    <p className="small">{description}</p>
                    <Wysiwyg
                        children={value || ''}
                        name={name}
                        id={name}
                        onChange={(value) => {
                            handleWysiwigInputChange(name, value);
                        }}
                    />
                    
                </div>
                {errors && errors[name] && errors[name].map((message, index) => (
                    <Danger message={message} key={index} />
                ))}
            </div>
        );
    }
};

export default GameTitleForm;
